import React from 'react';
import parse from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var HTMLRenderer = function HTMLRenderer(_ref) {
  var _ref$html = _ref.html,
      html = _ref$html === void 0 ? '' : _ref$html,
      _ref$components = _ref.components,
      components = _ref$components === void 0 ? {} : _ref$components,
      _ref$componentOverrid = _ref.componentOverrides,
      componentOverrides = _ref$componentOverrid === void 0 ? {} : _ref$componentOverrid;
  var resolvedComponents = Object.keys(componentOverrides).reduce(function (acc, key) {
    var Comp = components[key] || function (props) {
      return React.createElement(key, props);
    };

    acc[key] = componentOverrides[key](Comp);
    return acc;
  }, _objectSpread2({}, components));
  var parserOptions = {
    replace: function replace(_ref2) {
      var name = _ref2.name,
          attribs = _ref2.attribs,
          nodeChildren = _ref2.children;
      var children = nodeChildren ? domToReact(nodeChildren, parserOptions) : null;
      var Comp = resolvedComponents[name];
      if (!Comp) return;
      return React.createElement(Comp, _objectSpread2({
        name: name
      }, attribs), children);
    }
  };
  return parse(html, parserOptions);
};

export default HTMLRenderer;
